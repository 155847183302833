import apiClient from 'services/axios'
import store from 'store'
import config from '../../config'

// const BASEURL = `https://yk9s7at863.execute-api.ca-central-1.amazonaws.com/dev/`
const BASEURL = config.apiUrl+"/"+config.stage+"/"

// eslint-disable-next-line import/prefer-default-export
export function initCallHistory(payload) {
  return apiClient
    .post(`${BASEURL}/callhistory`, payload, {
      headers: { Authorization: `Bearer ${store.get('authToken')}` },
    })
    .then((response) => {
      if (response) {
        return response.data
      }
      return {}
    })
}

export function updateCallHistory(payload) {
  return apiClient
    .put(`${BASEURL}/callhistory`, payload, {
      headers: { Authorization: `Bearer ${store.get('authToken')}` },
    })
    .then((response) => {
      if (response) {
        return response.data
      }
      return {}
    })
}

export function getCallHistoryByProviderId(providerId, roleType) {
  if (roleType === 'DOCTOR') {
    return apiClient
      .get(`${BASEURL}/callhistory?employeeId=${providerId}`, {
        headers: { Authorization: `Bearer ${store.get('authToken')}` },
      })
      .then((response) => {
						const callhistory = response?.data?.result || response?.data || [] 

        if (callhistory) {
          return callhistory
        }
        return []
      })
  }
  return apiClient
    .get(`${BASEURL}/callhistory?companyId=${providerId}`, {
      headers: { Authorization: `Bearer ${store.get('authToken')}` },
    })
    .then((response) => {
      const callhistory = response?.data?.result || response?.data || [] 
      
      if (callhistory) {
        return callhistory
      }
      return []
    })
}
