import { Amplify } from 'aws-amplify'
import config from './config'

console.log('config', config)

Amplify.configure({
	Auth: {
		region: config.cognito.REGION,
		userPoolId: config.cognito.USER_POOL_ID,
		identityPoolId: config.cognito.IDENTITY_POOL_ID,
		userPoolWebClientId: config.cognito.APP_CLIENT_ID,
	},
	Storage: {
		region: config.s3.REGION,
		bucket: config.s3.BUCKET,
		identityPoolId: config.cognito.IDENTITY_POOL_ID,
		customPrefix: { public: '' },
	},
	API: {
		aws_project_region: 'ca-central-1',
		aws_appsync_graphqlEndpoint: 'https://7m3ooue4hngkrll47dwufq3w2e.appsync-api.ca-central-1.amazonaws.com/graphql',
		aws_appsync_region: 'ca-central-1',
		aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
		aws_appsync_apiKey: 'hof6gxwhoregfpd4ftjfokzn54',

		// aws_project_region: config.graphql.app_region,
		// aws_appsync_graphqlEndpoint: config.graphql.endpoint,
		// aws_appsync_region: config.graphql.app_region,
		// aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
		// aws_appsync_apiKey: config.graphql.key,
		
		endpoints: [
			{
				name: 'ONRX_API',
				endpoint: config.ONRX_API.apiGateway.URL,
				region: config.ONRX_API.apiGateway.REGION,
			},
			{
				name: 'WELLNESS_API',
				endpoint: config.apiUrl,
				region: config.ONRX_API.apiGateway.REGION,
			},
		],
	},
})
